import { APEXEnvironment } from '../app/utils/types';

export const environment: APEXEnvironment = {
  staging: false,
  production: true,
  test: false,
  assetsUrl: 'https://sandy.apexapp.io/assets',
  api: 'https://sandbox.apexapp.io',
  restApi: 'https://rest-sandbox.apexapp.io',
  apexUrl: 'https://sandbox.apexapp.io',
  appUrl: 'https://sandbox.apexapp.io',
  inspectionUrl: 'https://inspection.apexapp.io',
  printUrl: 'https://print.apexapp.io',
  reportingUrl: 'https://reporting.apexapp.io',
  requestsUrl: 'https://requests.apexapp.io',
  portalUrl: 'https://portal.apexapp.io',
  bucket: 'files-sandbox.apexapp.io',
  bucketUrl: 'https://files-sandbox.apexapp.io',
  pdfViewerUrl: 'https://pdfviewer.apexapp.io',
  pdfWorkerSrc: 'https://sandbox.apexapp.io/assets/libs/pdf.worker.min.js',
  logoutUrl: 'https://sandbox.apexapp.io/auth/sign-out',

  transifexToken: '1/d21a0676ec72779a598b876fbf7c422f776a6e70',

  oAuthCallbackUrl: 'https://sandbox.apexapp.io/user/auth/code',

  pusherInstanceId: '0793a8ae-d3e8-4935-9bae-91f0c3831b76',
};
